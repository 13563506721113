import { IconDefinition, IconName, IconPack, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export const facSidebar: IconDefinition = {
    prefix: 'fac' as IconPrefix,
    iconName: 'sidebar' as IconName,
    icon: [
        24, // SVG view box width
        21, // SVG view box height
        [],
        '',
        'M2 0C0.895431 0 0 0.89543 0 2V19C0 20.1046 0.89543 21 2 21H22C23.1046 21 24 20.1046 24 19V2C24 0.895431 23.1046 0 22 0H2ZM22 1.5C22.2761 1.5 22.5 1.72386 22.5 2V19C22.5 19.2761 22.2761 19.5 22 19.5H11.25V1.5H22ZM2 1.5H9.75V19.5H2C1.72386 19.5 1.5 19.2761 1.5 19V2C1.5 1.72386 1.72386 1.5 2 1.5Z M3.25 8.25C3.25 7.83579 3.58579 7.5 4 7.5H7C7.41421 7.5 7.75 7.83579 7.75 8.25C7.75 8.66421 7.41421 9 7 9H4C3.58579 9 3.25 8.66421 3.25 8.25Z M4 10.5C3.58579 10.5 3.25 10.8358 3.25 11.25C3.25 11.6642 3.58579 12 4 12H7C7.41421 12 7.75 11.6642 7.75 11.25C7.75 10.8358 7.41421 10.5 7 10.5H4Z M4 4.5C3.58579 4.5 3.25 4.83579 3.25 5.25C3.25 5.66421 3.58579 6 4 6H7C7.41421 6 7.75 5.66421 7.75 5.25C7.75 4.83579 7.41421 4.5 7 4.5H4Z',
    ],
} as IconDefinition;

export const facSaveSuccess: IconDefinition = {
    prefix: 'fac' as IconPrefix,
    iconName: 'save-success' as IconName,
    icon: [
        24, // SVG view box width
        21, // SVG view box height
        [],
        '',
        [
            'M2.35767 19.8571V4.14285C2.35767 3.71071 2.71133 3.35715 3.14355 3.35715H3.92944V8.46428C3.92944 9.11742 4.45502 9.64285 5.10828 9.64285H14.539C15.1922 9.64285 15.7178 9.11742 15.7178 8.46428V3.54866L15.7571 3.58795L19.4213 7.24152L19.4164 7.24643C19.5638 7.39375 19.6472 7.59509 19.6472 7.80135V11.1949C20.5037 11.4015 21.2987 11.7655 22 12.2547V7.80624C22 6.97144 21.6709 6.17097 21.0815 5.5817L21.0864 5.57678L17.4271 1.9183C16.8377 1.32903 16.037 1 15.2021 1H3.14355C1.4097 1 0 2.40938 0 4.14285V19.8571C0 21.5906 1.4097 23 3.14355 23H13.101C12.4235 22.3361 11.8803 21.5355 11.5161 20.6429H3.14355C2.71133 20.6429 2.35767 20.2893 2.35767 19.8571ZM6.28711 7.28572V3.35715H13.3601V7.28572H6.28711Z M13.2049 12.9003C11.8476 14.177 11 15.9895 11 18C11 18.0957 11.0019 18.1909 11.0057 18.2857H11.0024C10.1687 18.2857 9.36917 17.9546 8.77963 17.3652C8.19009 16.7758 7.85889 15.9764 7.85889 15.1429C7.85889 14.3093 8.19009 13.5099 8.77963 12.9205C9.36917 12.3311 10.1687 12 11.0024 12C11.8266 12 12.6173 12.3235 13.2049 12.9003Z',
            'M18 24C19.5913 24 21.1174 23.3679 22.2426 22.2426C23.3679 21.1174 24 19.5913 24 18C24 16.4087 23.3679 14.8826 22.2426 13.7574C21.1174 12.6321 19.5913 12 18 12C16.4087 12 14.8826 12.6321 13.7574 13.7574C12.6321 14.8826 12 16.4087 12 18C12 19.5913 12.6321 21.1174 13.7574 22.2426C14.8826 23.3679 16.4087 24 18 24ZM20.6484 16.8984L17.6484 19.8984C17.4281 20.1188 17.0719 20.1188 16.8539 19.8984L15.3539 18.3984C15.1336 18.1781 15.1336 17.8219 15.3539 17.6039C15.5742 17.3859 15.9305 17.3836 16.1484 17.6039L17.25 18.7055L19.8516 16.1016C20.0719 15.8813 20.4281 15.8813 20.6461 16.1016C20.8641 16.3219 20.8664 16.6781 20.6461 16.8961L20.6484 16.8984Z',
        ],
    ],
} as IconDefinition;

export const facSaveWarning: IconDefinition = {
    prefix: 'fac' as IconPrefix,
    iconName: 'save-warning' as IconName,
    icon: [
        24, // SVG view box width
        21, // SVG view box height
        [],
        '',
        [
            'M2.35767 19.8571V4.14285C2.35767 3.71071 2.71133 3.35715 3.14355 3.35715H3.92944V8.46428C3.92944 9.11742 4.45502 9.64285 5.10828 9.64285H14.539C15.1922 9.64285 15.7178 9.11742 15.7178 8.46428V3.54866L15.7571 3.58795L19.4213 7.24152L19.4164 7.24643C19.5638 7.39375 19.6472 7.59509 19.6472 7.80135V11.1949C20.5037 11.4015 21.2987 11.7655 22 12.2547V7.80624C22 6.97144 21.6709 6.17097 21.0815 5.5817L21.0864 5.57678L17.4271 1.9183C16.8377 1.32903 16.037 1 15.2021 1H3.14355C1.4097 1 0 2.40938 0 4.14285V19.8571C0 21.5906 1.4097 23 3.14355 23H13.101C12.4235 22.3361 11.8803 21.5355 11.5161 20.6429H3.14355C2.71133 20.6429 2.35767 20.2893 2.35767 19.8571ZM6.28711 7.28572V3.35715H13.3601V7.28572H6.28711Z M13.2049 12.9003C11.8476 14.177 11 15.9895 11 18C11 18.0957 11.0019 18.1909 11.0057 18.2857H11.0024C10.1687 18.2857 9.36917 17.9546 8.77963 17.3652C8.19009 16.7758 7.85889 15.9764 7.85889 15.1429C7.85889 14.3093 8.19009 13.5099 8.77963 12.9205C9.36917 12.3311 10.1687 12 11.0024 12C11.8266 12 12.6173 12.3235 13.2049 12.9003Z',
            'M18 24C19.5913 24 21.1174 23.3679 22.2426 22.2426C23.3679 21.1174 24 19.5913 24 18C24 16.4087 23.3679 14.8826 22.2426 13.7574C21.1174 12.6321 19.5913 12 18 12C16.4087 12 14.8826 12.6321 13.7574 13.7574C12.6321 14.8826 12 16.4087 12 18C12 19.5913 12.6321 21.1174 13.7574 22.2426C14.8826 23.3679 16.4087 24 18 24ZM18 15C18.3117 15 18.5625 15.2508 18.5625 15.5625V18.1875C18.5625 18.4992 18.3117 18.75 18 18.75C17.6883 18.75 17.4375 18.4992 17.4375 18.1875V15.5625C17.4375 15.2508 17.6883 15 18 15ZM17.25 20.25C17.25 20.0511 17.329 19.8603 17.4697 19.7197C17.6103 19.579 17.8011 19.5 18 19.5C18.1989 19.5 18.3897 19.579 18.5303 19.7197C18.671 19.8603 18.75 20.0511 18.75 20.25C18.75 20.4489 18.671 20.6397 18.5303 20.7803C18.3897 20.921 18.1989 21 18 21C17.8011 21 17.6103 20.921 17.4697 20.7803C17.329 20.6397 17.25 20.4489 17.25 20.25Z',
        ],
    ],
} as IconDefinition;

export const facDetails: IconDefinition = {
    prefix: 'fac' as IconPrefix,
    iconName: 'details' as IconName,
    icon: [
        24, // SVG view box width
        24, // SVG view box height
        [],
        '',
        [
            'M2.25 21C2.25 21.4125 2.5875 21.75 3 21.75H12.1424C13.196 22.3848 14.4303 22.75 15.75 22.75C16.3886 22.75 17.0073 22.6645 17.5951 22.5043C17.0752 23.3981 16.1068 24 15 24H3C1.34531 24 0 22.6547 0 21V3C0 1.34531 1.34531 0 3 0H10.7578C11.5547 0 12.3141 0.314063 12.8766 0.876563L17.1234 5.11875C17.6859 5.68125 18 6.44531 18 7.24219V9.11945C17.2939 8.87991 16.5371 8.75 15.75 8.75V7.5H12C11.1703 7.5 10.5 6.82969 10.5 6V2.25H3C2.5875 2.25 2.25 2.5875 2.25 3V21Z M9.83815 12H5.625C5.00156 12 4.5 12.5016 4.5 13.125C4.5 13.7484 5.00156 14.25 5.625 14.25H8.91109C9.08828 13.4384 9.40626 12.6795 9.83815 12Z M8.78971 16.5H5.625C5.00156 16.5 4.5 17.0016 4.5 17.625C4.5 18.2484 5.00156 18.75 5.625 18.75H9.42363C9.09363 18.0553 8.87467 17.2977 8.78971 16.5Z M21.3756 15.6867C21.3756 16.9416 20.9681 18.1008 20.2818 19.0413L23.7436 22.5052C24.0855 22.8469 24.0855 23.4019 23.7436 23.7437C23.4018 24.0854 22.8467 24.0854 22.5049 23.7437L19.043 20.2797C18.1023 20.9687 16.9429 21.3733 15.6878 21.3733C12.5458 21.3733 10 18.828 10 15.6867C10 12.5453 12.5458 10 15.6878 10C18.8297 10 21.3756 12.5453 21.3756 15.6867ZM15.6878 19.6236C16.2049 19.6236 16.7169 19.5218 17.1947 19.3239C17.6724 19.1261 18.1065 18.8361 18.4721 18.4705C18.8378 18.1049 19.1278 17.6709 19.3257 17.1933C19.5236 16.7156 19.6255 16.2037 19.6255 15.6867C19.6255 15.1697 19.5236 14.6577 19.3257 14.1801C19.1278 13.7024 18.8378 13.2684 18.4721 12.9028C18.1065 12.5373 17.6724 12.2473 17.1947 12.0494C16.7169 11.8516 16.2049 11.7497 15.6878 11.7497C15.1707 11.7497 14.6586 11.8516 14.1809 12.0494C13.7031 12.2473 13.2691 12.5373 12.9034 12.9028C12.5378 13.2684 12.2477 13.7024 12.0498 14.1801C11.8519 14.6577 11.7501 15.1697 11.7501 15.6867C11.7501 16.2037 11.8519 16.7156 12.0498 17.1933C12.2477 17.6709 12.5378 18.1049 12.9034 18.4705C13.2691 18.8361 13.7031 19.1261 14.1809 19.3239C14.6586 19.5218 15.1707 19.6236 15.6878 19.6236Z',
        ],
    ],
} as IconDefinition;

export const artemisIconPack: IconPack = {
    facSidebar,
    facSaveSuccess,
    facSaveWarning,
    facDetails,
};
